import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { getComponentSettings, removeSpecialCharacters } from 'libs/content';
import MediumWidth from 'layouts/medium-width';
import Button from 'components/forms/Button';
import Spacing from 'layouts/Spacing';
import { AnchorLink } from 'components/Link';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import Box from 'components/forms/Box';
import Loading from 'components/Loading';

const List = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;

const ListItem = styled.li`
	border-radius: ${p => p.theme.utils.borderRadius};
	padding: 20px;
	&:nth-child(odd) {
		background-color: ${p => transparentize(0.6, p.theme.colors.blue100)};
	}
`;

const Wrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	${p =>
		p.theme.media.XSmallOnly(css`
			flex-direction: column;
			align-items: flex-start;
		`)}
`;

const ColumnLeft = styled.div`
	width: calc(100% - 120px);
	${p =>
		p.theme.media.XSmallOnly(css`
			width: 100%;
		`)}
	a {
		font-weight: 600;
	}
`;

const ColumnRight = styled.div`
	width: 120px;
	${p =>
		p.theme.media.smallOnly(css`
			a.small {
				justify-content: center;
			}
		`)}
	${p =>
		p.theme.media.XSmallOnly(css`
			width: 100%;
			margin-top: 5px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column-reverse;
		`)}
`;

const Desc = styled.div`
	margin-top: 0;
	display: block;
	span {
		&:first-of-type:not(:last-of-type):after {
			content: ', ';
		}
	}
`;

const Deadline = styled.span`
	font-size: 14px;
	line-height: 20px;
	margin-top: 5px;
	display: block;
	${p =>
		p.theme.media.XSmallOnly(css`
			margin-top: 0px;
			margin-bottom: 10px;
		`)}
`;

/**
 * Represents a list of job vacancies from Easycruit.
 * @param {string} id - The id of the component
 * @param {string} title - The title above the list
 * @param {string} intro - The introduction text above the list
 * @param {string} keyword - The keywords to filter the list results
 * @param {array} settings - The component settings
 * @param {string} target - The button´s target (to open in a new browser tab or not)
 * @param {string} headinglevel - The title´s heading level
 * @param {...object} props - Rest of the parameters for the component
 */
export default function JobVacancies({
	id = 'ledige-stillinger',
	title = '',
	intro = '',
	keyword = '',
	settings = [],
	headinglevel = 'h2',
	...props
}) {
	const { positions, filteredPositions, loading } = useGetVacancies({
		search: keyword,
	});

	const componentSettings = getComponentSettings({ settings, props });

	const combinedPositions =
		filteredPositions?.length > 0 ? filteredPositions : positions;

	// If hideifnovacancies is true and no vacancies are found, return null
	if (
		componentSettings?.hideifnovacancies === 'true' &&
		!loading &&
		!!(
			(keyword && filteredPositions?.length === 0) ||
			positions?.length === 0
		)
	) {
		return null;
	}

	// If loading show loading spinner
	if (loading) return <Loading />;

	return (
		<Spacing
			className="component__job-vacancies"
			data-cy="component__job-vacancies"
			{...props}>
			<MediumWidth
				id={title ? removeSpecialCharacters(title) : id}
				className="medium-width">
				{componentSettings?.hidetitle !== 'true' && (
					<TitleAndText
						title={title}
						text={intro}
						headinglevel={headinglevel}
						settings={settings}
						nested={true}
						spacing={{ bottom: 'none' }}
						style={{ marginBottom: '20px' }}
					/>
				)}

				{(!loading && combinedPositions.length > 0 && (
					<div id="stillinger">
						{keyword && !filteredPositions?.length > 0 && (
							<p style={{ marginBottom: '45px' }}>
								Ingen stillinger innenfor denne kategorien for
								øyeblikket. Se alle andre ledige stillinger
								nedenfor.
							</p>
						)}

						{((keyword && filteredPositions?.length > 0) ||
							positions?.length > 0) && (
							<div>
								<List
									className="job-vacancies"
									id="positions-table-list">
									{combinedPositions?.map(position => {
										return (
											<ListItem
												key={
													position?.position ||
													position?.url
												}>
												<Wrap>
													<ColumnLeft>
														<AnchorLink
															target="_blank"
															rel="noreferrer"
															href={position.url}>
															{position?.position}
														</AnchorLink>
														<Desc>
															{position?.extent && (
																<span>
																	{
																		position.extent
																	}
																</span>
															)}

															{position && (
																<span>
																	{getPositionDescription(
																		position
																	)}
																</span>
															)}
														</Desc>
													</ColumnLeft>
													<ColumnRight>
														<Button
															href={position?.url}
															small={true}>
															Send søknad
														</Button>
														{position.deadline && (
															<Deadline>
																{`Frist: ${position.deadline}`}
															</Deadline>
														)}
													</ColumnRight>
												</Wrap>
											</ListItem>
										);
									})}
								</List>
							</div>
						)}
					</div>
				)) || (
					<Box className="no-vacancies">
						Ingen ledige stillinger akkurat nå.
					</Box>
				)}
			</MediumWidth>
		</Spacing>
	);
}

/**
 * Hook for fetching vacancies from EasyCruit XML feed
 * @param {object} props - The props for the hook
 * @param {string} props.search - The keywords to filter the list results
 * @returns {object} - The loading state, the positions and the filtered positions
 */
function useGetVacancies({ search }) {
	const [loading, setLoading] = useState(true);
	const [positions, setPositions] = useState([]);
	const [filteredPositions, setFilteredPositions] = useState([]);

	async function getPositions() {
		try {
			const response = await fetch(
				'https://nte.easycruit.com/export/xml/vacancy/list.xml'
			);
			const data = await response.text();
			const parser = new DOMParser();
			const xmlDoc = parser.parseFromString(data, 'text/xml');
			const vacancies = xmlDoc.getElementsByTagName('Vacancy');

			const getTextContent = (parent, tag) =>
				parent.getElementsByTagName(tag)[0]?.textContent;
			const getVacancyDetail = (vacancy, id) => {
				const categoryItems = vacancy.getElementsByTagName('Item');
				return Array.from(categoryItems).find(
					item => item.id.indexOf(id) >= 0
				)?.textContent;
			};

			const results = Array.from(vacancies).map(vacancy => {
				const departmentXML =
					vacancy.getElementsByTagName('Department')[0];
				const department =
					departmentXML.getElementsByTagName('Name')[0].textContent;

				const position = getTextContent(vacancy, 'Title');
				const deadline = getTextContent(vacancy, 'ApplicationDeadline');
				const departmentAlt = getTextContent(
					vacancy,
					'AlternativeCompanyName'
				);

				const url = getTextContent(vacancy, 'VacancyURL');
				const extent = getVacancyDetail(vacancy, 'fulltime');
				const duration = getVacancyDetail(vacancy, 'contract');

				return {
					department,
					departmentAlt,
					position,
					deadline,
					url,
					extent,
					duration,
				};
			});

			// Split the search words and remove whitespaces
			const searchWords = search?.replace(/\s/g, '').split(',') || [];

			/**
			 * Filter the positions based on the search words
			 * @param {Array} acc - The accumulator
			 * @param {Object} position - The position object
			 * @param {string} position.position - The position title
			 * @param {string} position.department - The position department
			 * @returns {Array} - The filtered positions
			 */
			const searchedPositions = results.reduce(
				(
					acc,
					{
						department,
						departmentAlt,
						position,
						deadline,
						url,
						extent,
						duration,
					}
				) => {
					const isMatch = searchWords.some(word => {
						const lowerCaseWord = word.toLowerCase();
						return (
							position.toLowerCase().includes(lowerCaseWord) ||
							department?.includes(search)
						);
					});

					if (isMatch) {
						acc.push({
							department,
							departmentAlt,
							position,
							deadline,
							url,
							extent,
							duration,
						});
					}

					return acc;
				},
				[]
			);

			setPositions(results);
			if (searchedPositions.length) {
				setFilteredPositions(searchedPositions);
			}

			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error('Error fetching vacancies', error);
		}
	}

	useEffect(() => {
		getPositions();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		getPositions();
		// eslint-disable-next-line
	}, [search]);

	return { loading, positions, filteredPositions };
}

/**
 * Get the position description
 * @param {object} position - The position object
 * @returns {string} - The position description
 */
function getPositionDescription(position) {
	if (position.duration && position.departmentAlt) {
		return `${position.duration} i ${position.departmentAlt}`;
	} else if (position.duration && position.department) {
		return `${position.duration} i ${position.department}`;
	} else if (position.department) {
		return position.department;
	}
	return null;
}
